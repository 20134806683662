import { CACHE_KEY_READED_EVENTS } from '@/constants'
import {
  CreationInputAtom,
  CreationModeEnum,
  Creation,
  Persona,
  ErrorCodeEnum,
  CreationOutput,
  ModelVersion,
  Nullable,
  CreationOutputBasic,
} from '@/types'
import { getLocalStorage } from '@/utils/base'
import dayjs, { Dayjs } from 'dayjs'
import { atom, useAtom } from 'jotai'
import { nanoid } from 'nanoid'

export const jobsRefreshKeyAtom = atom<string>(nanoid())
export const showModeListAtom = atom<boolean>(false)
export const showCreationsAtom = atom<boolean>(false)

const isMobile = () => {
  if (typeof window === 'undefined') return false
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const creationInputAtom = atom<CreationInputAtom>({
  mode: CreationModeEnum.CreateHD,
  expanded: !isMobile(),
  version: ModelVersion.TWO,
})

export const allowedNSFWCreationIdsAtom = atom<string[]>([])

// storage latest data for single creation items, used for override list data without re-fetching all creations
// should be cleared when user navigates to other pages
export const creationCacheAtom = atom<Record<string, Creation>>({})
export const outputCacheAtom = atom<Record<string, CreationOutput>>({})

export const creationCoolDownAtom = atom<Date | null>(null)

export const showBlockedDialogAtom = atom<boolean>(false)

// deleted jobs
export const deletedCreationIdsAtom = atom<string[]>([])

export const hasBroadcastAtom = atom(false)

export const personaAtom = atom<Partial<Persona> | null>(null)

// export const volumeAtom = atom<number>(1)
export const volumeAtom = atom<number>(0.5) // default volume to 0.5
export const mutedAtom = atom<boolean>(true)

export const loginDialogOpenAtom = atom<boolean>(false)
export const getCreditDialogOpenAtom = atom<boolean>(false)

export const getTobCreditDialogOpenAtom = atom<boolean>(false)
export const insufficientDialogOpenAtom = atom<boolean>(false)
export const versionDialogOpenAtom = atom<boolean>(false)

export const auth0SignInAtom = atom<boolean>(typeof window !== 'undefined' && !!localStorage.getItem('token'))
export const auth0Loading = atom<boolean>(true)
export const auth0FirstLoginAtom = atom<boolean | null>(null)

export const subscriptionDialogContentAtom = atom<{
  code?: ErrorCodeEnum
  message: string
} | null>(null)

export const generationErrorDialogContentAtom = atom<{
  code?: ErrorCodeEnum
  message: string
} | null>(null)

export const creationInputPrivateModeAtom = atom<boolean>(false)

export const hideOnboardingAtom = atom<boolean>(false)

export const likeCacheAtom = atom<Record<string, boolean>>({})

export const readedNotificationIdsAtom = atom<string[]>([])

export const unreadNotificationCountRefreshKeyAtom = atom<string>(nanoid())
export const enableAfcAtom = atom<boolean>(false)

export const activeTemplateIdAtom = atom<string | null>(null)

export const showCreationModeTutorialAtom = atom<boolean>(false)

export const expandedTemplateCategoriesAtom = atom<string[]>(['hot'])

export const respectTemplateClientNameAtom = atom<boolean>(true)

export const creationSubmitDialogPayloadAtom = atom<
  Nullable<{
    creation: Creation | CreationOutput | CreationOutputBasic
    type?: 'event' | 'spotlight' | 'showcase'
  }>
>(null)

export const templateSocialMediaRefreshKeyAtom = atom<string>(nanoid())
export const parallelRouteOpenAtom = atom<boolean>(false)
export const readedEventsAtom = atom<string[]>(getLocalStorage<string[]>(CACHE_KEY_READED_EVENTS) ?? [])
